import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { SocietiesService, Society, StringUtils } from '@pwc-ecobonus/common';
import { I18nService } from '@pwc-ecobonus/i18n';
import { piAdd, piCalendarSmall, piClear, piDelete, piEdit, piView, PwcIconsLibrary } from '@pwc-ecobonus/icons';
import { environment } from 'src/environments/environment';
import { RoutePath } from '../../../../enums/route-path.enum';
import { BasePage } from '../../../base-page/base-page';
import { FaqResource } from '../../models/api/faq-resource';
import { Faq } from '../../models/faq';
import { FaqCategory } from '../../models/faq-category';
import { FaqCategoryService } from '../../services/faq-category.service';
import { FaqService } from '../../services/faq.service';
import { ViewChild } from '@angular/core';
import {ModalComponent} from '@pwc-ecobonus/ui';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent extends BasePage implements OnInit, AfterViewInit {
    faEllipsisV = faEllipsisV;
    categories: FaqCategory[] = [];

    dataSet: Faq[];
    selectedSociety: number;

    // filters
    filterText: string = "";
    filterCategory: number = null;

    validTimeForSupportRequest: boolean = false;

    @ViewChild('invalidTimeModal') invalidTimeModal: ModalComponent;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private i18n: I18nService,
        private pwcIconsLibrary: PwcIconsLibrary,
        private faqService: FaqService,
        private faqCategoryService: FaqCategoryService,
        private societiesService: SocietiesService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        super();

        this.pwcIconsLibrary.registerIcons([
            piView,
            piEdit,
            piDelete,
            piClear,
            piAdd,
            piCalendarSmall
        ]);
    }

    ngOnInit(): void {
        super.ngOnInit();

        // Go back if Dynamic FAQ are off
        // if (!this.environment.configuration.dynamicFaq)
        //     this.router.navigate([
        //         RoutePath.DASHBOARD
        //     ]);

        this.onSearch();

        if (!this.isFromLoginPage)
            this.loadFaqCategories();

        this.loadFaqs();

        if (!this.isFromLoginPage)
            this.loadValidTimeForSupportRequest();
    }

    onPageChange(page: any) {
        this.currentPage = page - 1;
        this.loadFaqs();
    }

    onPageSizeChange(size: any) {
        this.pageSize = size;
        this.loadFaqs();
    }

    get isDataSetEmpty(): boolean {
        return this.dataSet && this.dataSet.length === 0;
    }

    get isFromLoginPage(): boolean {
        return this.router.url.includes(RoutePath.LOGIN_FAQ);
    }

    private loadFaqCategories(): void {
        this.faqCategoryService.getCategories(50).subscribe((options: any) => {
            this.categories = [...options];
        });
    }

    ngAfterViewInit(): void {
        this.changeDetector.detectChanges();
    }

    initBreadcrumbs(): void {
        this.breadcrumbs = [
            {
                label: this.i18n.getValue('breadcrumbs.faq.base'),
                url: this.buildRoute([
                    RoutePath.CONFIGURATION,
                    this.RoutePath.FAQ_LIST
                ])
            }
        ];
    }

    private loadFaqs() {
        const search: Partial<FaqResource> = this.configFilters();
        search.page = this.currentPage;
        search.size = this.pageSize;

        if (!this.isFromLoginPage) {
            this.subscriptions.push(
                this.societiesService.getSelectedSociety().subscribe(
                    (society: Society) => {
                        this.selectedSociety = society.id;
                        search.societies = [society.id];
                        this.subscriptions.push(
                            this.faqService.search(search).subscribe(
                                (data: any) => {
                                    this.pagination = {
                                        content: data.content,
                                        number: data.number,
                                        size: data.size,
                                        totalElements: data.totalElements,
                                        numberOfElements: data.numberOfElements,
                                    };
                                    this.dataSet = [...data.content];
                                    // reset loader visibility
                                    this.loadingSubject.next(false);
                                },
                                (error) => {
                                    this.loadingSubject.next(false);
                                }));
                    })
            );
        }
        else {
            this.subscriptions.push(
                this.faqService.getLoginFaqs(search).subscribe(
                    (data: any) => {
                        this.pagination = {
                            content: data.content,
                            number: data.number,
                            size: data.size,
                            totalElements: data.totalElements,
                            numberOfElements: data.numberOfElements,
                        };
                        this.dataSet = [...data.content];

                        if (data.content && data.content.length > 0) {
                            this.validTimeForSupportRequest = data.content[0].validTime;
                        }
                    }
                )
            )
        }
    }

    private configFilters(): Partial<FaqResource> {
        const search: Partial<FaqResource> = {};
        if (this.filterText && !StringUtils.isEmpty(this.filterText))
            search.text = this.filterText;
        if (this.filterCategory)
            search.categoryIds = [this.filterCategory];
        return search;
    }

    onChangeFilters() {
        this.loadFaqs();
    }

    askForSupportRequest(): void {
        if (!this.validTimeForSupportRequest) {
            this.invalidTimeModal.open();
            return;
        }
        if (this.isFromLoginPage) {
            // var link = document.createElement('a');
            // link.href = `mailto:${environment.configuration.helpDeskEmail}`;
            // link.click();
            this.router.navigate([
                RoutePath.ACCESS_PROBLEM
            ])
        } else {
            this.router.navigate([
                RoutePath.SUPPORT_REQUEST,
                RoutePath.NEW
            ]);
        }
    }


  showFooter(): boolean{
    // non provenienza da pagina di log in
    // oppure helpDeskEmail!=''
    let isHelpDeskEmailDefined : boolean = (environment.configuration.helpDeskEmail && environment.configuration.helpDeskEmail.length > 0);
    return (!this.isFromLoginPage || isHelpDeskEmailDefined);
  }

  mailToHelpDesk(): string {
    return `mailto:${environment.configuration.helpDeskEmail}`;
  }

  getHelpDeskEmail(): string {
    return environment.configuration.helpDeskEmail;
  }

    private loadValidTimeForSupportRequest() {
        const params: Partial<FaqResource> = {};
        this.subscriptions.push(
            this.societiesService.getSelectedSociety().subscribe(
                (society: Society) => {
                    this.selectedSociety = society.id;
                    params.societies = [society.id];
                    this.subscriptions.push(
                        this.faqService.isValidTimeForSupportRequest(params).subscribe(
                            (isValid: boolean) => {
                                this.validTimeForSupportRequest = isValid;
                            },
                            (error) => {
                                console.error(error);
                                this.validTimeForSupportRequest = false;
                            }));
                })
        );
    }
}

