<div class="page-container">
  <pwc-breadcrumbs *ngIf="!isFromLoginPage" [items]="breadcrumbs"></pwc-breadcrumbs>

  <div class="card">
    <div class="card-body">
      <h5 class="title">Hai bisogno di assistenza sulla piattaforma Ecobonus? </h5>
      <h5 class="sub-title">In questa sezione FAQ puoi trovare più velocemente la risposta alle tue domande.
        Naviga le cartelle e seleziona il tipo di richiesta.
        Se non troverai la soluzione potrai inviare la tua richiesta ai nostri esperti.</h5>

      <!-- FILTERS -->
      <div class="col-lg-12">

        <!-- text -->
        <div class="d-flex flex-row flex-wrap vertical-padding">
          <div class="form-group mr-4">
            <label class="form-input-label no-wrap-label" for="id" translate>
              Cerca per parole chiave
            </label>
            <div class="form-group form-input-group">
              <input type="text" class="filter filter-input filter-image" id="textSearch" name="textSearch"
                placeholder="Hai bisogno di aiuto? Scrivi qui l'argomento che ti interessa approfondire"
                [(ngModel)]="filterText" (ngModelChange)="onChangeFilters()">
            </div>
          </div>
        </div>

        <!-- category -->
        <div *ngIf="!this.isFromLoginPage" class="d-flex flex-row flex-wrap ">
          <div class="form-group filter-item mr-4">
            <label class="form-input-label no-wrap-label" for="name" translate>
              Cerca tra le categorie
            </label>
            <ng-select class="filter" [items]="categories" [(ngModel)]="filterCategory" bindLabel="name" bindValue="id"
              id="category" placeholder="Seleziona una categoria" [clearable]="true" [searchable]="true"
              (ngModelChange)="onChangeFilters()">
            </ng-select>
          </div>
        </div>
      </div>

      <!-- FAQs  -->
      <div *ngIf="!isDataSetEmpty">
        <div *ngFor="let faq of dataSet let i=index" class="mt-3">
          <app-faq-detail [faq]="faq" [index]="i"></app-faq-detail>
        </div>
      </div>
      <div *ngIf="isDataSetEmpty" class="center mt-3">
        <h5>La tua ricerca non ha fornito nessun risultato, riprova con altre parole chiave.</h5>
      </div>

      <!-- Pageable -->
      <div *ngIf="!isDataSetEmpty" class="row justify-content-between table-paginator mt-3">

        <!-- Page number -->
        <div class="col-md-4">
          <pwc-pagination *ngIf="pagination" [page]="pagination.number + 1" [pageSize]="pagination.size"
            [collectionSize]="pagination.totalElements" (pageChange)="onPageChange($event)">
          </pwc-pagination>
        </div>

        <!-- Page size -->
        <div class="col-md-4">
          <pwc-page-size-selector [pageSize]="pageSize" (pageSizeChange)="onPageSizeChange($event)">
          </pwc-page-size-selector>
        </div>

      </div>

      <!-- Footer -->
      <div *ngIf="showFooter()" class="vertical-padding">
        <!-- <ng-container *ngIf="isFromLoginPage">
          <h5 class="sub-title ml-3">
            Non hai trovato quello che stai cercando?
            <a class="external-link mb-1 mr-4" [href]="mailToHelpDesk()">{{getHelpDeskEmail()}}</a>
          </h5>
        </ng-container> -->
        <!-- <ng-container *ngIf="!isFromLoginPage"> -->
        <h5 class="col-4 sub-title">Non hai trovato quello che stai cercando? </h5>
        <button class="col-3 btn btn-primary btn-rounded mb-2" (click)="askForSupportRequest()">
          <span class="d-inline-block" translate>&nbsp;
            CONTATTA IL NOSTRO HELPDESK
          </span>
        </button>
        <!-- </ng-container> -->
      </div>
    </div>
  </div>

  <pwc-modal #invalidTimeModal id="invalidTimeModal">
    <div modal-title>
      <h5 class="modal-title text-uppercase">
        HELPDESK
      </h5>
    </div>
    <div modal-body>
      <div class="form-group form-input-group">
        Gentile Cliente, la informiamo che il servizio di apertura di richieste di assistenza tramite apposito Form &egrave; attivo dal Luned&igrave; al Venerd&igrave; non festivi, dalle ore 8:30 alle ore 19:00. 
        La informiamo inoltre che eventuali richieste di supporto non attinenti alle categorie sotto previste non saranno prese in carico.
        </div>
      </div>
      <div modal-footer style="flex:1">
        <div modal-footer class="text-right">
        </div>
      </div>
  </pwc-modal>