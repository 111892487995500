<div ngbAccordion #accFaq="ngbAccordion">
  <div ngbAccordionItem="faq-list_{{index}}"
       [collapsed]="!(accFaq.isExpanded('faq-list_'+index))"
       class="accordion">

    <!-- Question (accordion header) -->
    <div ngbAccordionHeader class="card">
      <div class="card-header d-flex align-items-center justify-content-between"
           (click)="accFaq.toggle('faq-list_'+index)">
        <h5 class="font-weight-bold text-color" translate>
          {{index + 1}}.
          {{faq.question}}
        </h5>
        <div *ngIf="!(accFaq.isExpanded('faq-list_'+index))">
          <img src="assets/images/icons/ic_arrow_bottom.svg">
        </div>
        <div *ngIf="accFaq.isExpanded('faq-list_'+index)">
          <img src="assets/images/icons/ic_arrow_top.svg">
        </div>
      </div>
    </div>

    <!-- Answer (accordion content) -->
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <div class="card card-body">
          <ckeditor #ckeditor [editor]="editor" [config]="editorConfig" [innerHTML]="faq.answer" [disabled]="true">
          </ckeditor>
        </div>
      </div>
    </div>
  </div>
</div>
